import IndexLogin from '@/views/login/index.vue';

export default [
  {
    path: '',
    name: 'index',
    component: IndexLogin,
    children: [
      {
        path: '',
        name: 'sign-in',
        component: () => import('@/views/login/signing-view.vue'),
        meta: { title: 'Bem vindo(a),', subtitle: 'Faça o seu login', layout: 'login' },
      },
      {
        path: 'recuperar-senha',
        name: 'recover-password',
        component: () => import('@/views/login/recover-view.vue'),
        meta: { title: 'Recupere sua senha' },
      },
      {
        path: 'cadastrar-senha',
        name: 'create-password',
        component: () => import('@/views/login/create-password-view.vue'),
        meta: { title: 'Cadastre sua senha' },
        props: true,
      },
      {
        path: 'nova-senha',
        name: 'new-password',
        component: () => import('@/views/login/create-password-view.vue'),
        meta: { title: 'Nova senha' },
        props: true,
      },
      {
        path: 'callback/zitrus',
        name: 'login-auth-provider',
        component: () => import('@/views/login/callback-auth-provider.vue'),
        meta: { title: 'Bem vindo(a),', subtitle: 'Autenticando' },
        props: (route) => ({
          code: route.query?.code,
          sessionState: route.query?.session_state,
          state: route.query?.state,
        }),
      },
      {
        path: 'convite/:userId/:clientId',
        name: 'convite',
        component: () => import('@/views/convite.vue'),
        meta: {
          layout: 'convite',
        },
      },
    ],
  },
];
