import Vue from 'vue';
import VueRouter from 'vue-router';
import ApplicationHome from '@/views/application-home.vue';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: ApplicationHome,
      children: routes,
    },
  ],
});

export default router;
