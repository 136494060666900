import Vue from 'vue';
import { goToRoute, removeCookie, setCookie } from '@zitrus/utils-service';
import router from '@/router';
import {
  callbackSigInAdService,
  createOrUpdatePasswordService,
  resetPasswordService,
  signInAdService,
  signInService,
  inviteAcceptedService,
} from '../services';

/*
  This mapped messages, came from backend (z-login), and if they change, we need to update this array.
 */
const newPasswordErrorsMap = [
  'A senha deve ter pelo menos 8 caracteres de comprimento.',
  'A senha deve conter pelo menos 1 caracteres em maiúscula.',
  'A senha deve conter pelo menos 1 caracteres numéricos.',
  'A senha deve conter pelo menos 1 caracteres especiais.',
];

const signIn = async ({ commit }, { userLogin, password }) => {
  await signInService({
    payload: { userLogin, password },
    onSuccess: async ({ data }) => {
      await setCookie('token', data?.accessToken);
      await setCookie('refreshToken', data?.refreshToken);

      commit('GENERIC_MUTATION', {
        property: 'user',
        payload: data,
      });

      goToRoute('/workspace/selecionar-empresa?hideHeader=true&hideMenu=true');
    },
    onCustomError: ({ response }) => {
      removeCookie('token');
      removeCookie('refreshToken');

      const message = response?.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao realizar login.');
    },
  });
};

const signInAd = async ({ commit }, { userLogin, authProviderType }) => {
  commit('GENERIC_MUTATION', {
    property: 'isLoading',
    payload: true,
  });

  await signInAdService({
    userLogin,
    authProviderType,
    onSuccess: async ({ data }) => {
      window.location.href = data?.authorizationUrl;
    },
    onCustomError: ({ response }) => {
      commit('GENERIC_MUTATION', {
        property: 'isLoading',
        payload: false,
      });

      const message = response?.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao realizar login via Microsoft.');
    },
  });
};

const callbackSigInAd = async ({ commit }, { clientCode, code, state }) => {
  commit('GENERIC_MUTATION', {
    property: 'isLoading',
    payload: true,
  });

  await callbackSigInAdService({
    clientCode,
    code,
    state,
    onSuccess: async ({ data }) => {
      await setCookie('token', data?.accessToken);
      await setCookie('refreshToken', data?.refreshToken);

      commit('GENERIC_MUTATION', {
        property: 'user',
        payload: data,
      });

      goToRoute('/workspace/selecionar-empresa?hideHeader=true&hideMenu=true');
    },
    onCustomError: ({ response }) => {
      removeCookie('token');
      removeCookie('refreshToken');

      const message = response?.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao realizar login via Microsoft.');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'isLoading',
        payload: false,
      });
    },
  });
};

const createOrUpdatePassword = async ({ commit }, { token, newPassword }) => {
  commit('GENERIC_MUTATION', {
    property: 'isLoading',
    payload: true,
  });

  await createOrUpdatePasswordService({
    token,
    payload: { password: newPassword },
    onSuccess: () => {
      Vue.$toast.success('Senha cadastrada com sucesso');
      router.push({ name: 'sigin-in' });
    },
    onCustomError: ({ response }) => {
      if (Array.isArray(response?.data?.detail)) {
        const newFieldsErrors = [];

        response.data.detail.forEach((errorMsg) => {
          const key = newPasswordErrorsMap.find((errorStr) => errorStr === errorMsg);

          if (key) {
            newFieldsErrors.push(errorMsg);
          }
        });

        commit('GENERIC_MUTATION', {
          property: 'newPasswordFieldErrors',
          payload: newFieldsErrors,
        });
      }

      Vue.$toast.error('Ocorreu um erro de requisição ao realizar o cadastro de senha.');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'isLoading',
        payload: false,
      });
    },
  });
};

const resetPassword = async ({ commit }, { userLogin }) => {
  commit('GENERIC_MUTATION', {
    property: 'isLoading',
    payload: true,
  });

  await resetPasswordService({
    email: userLogin,
    onSuccess: () => {
      Vue.$toast.success('Recuperação de senha enviado para o e-mail cadastrado');
      router.push({ name: 'sigin-in' });
    },
    onCustomError: ({ response }) => {
      const message = response?.data?.detail
        ? response.data?.detail
        : response?.data?.message ?? response?.data?.title;

      Vue.$toast.error(message ?? 'Ocorreu um erro de requisição ao realizar o cadastro de senha.');
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'isLoading',
        payload: false,
      });
    },
  });
};

const updateFieldLogin = ({ commit }, { property, payload }) => {
  commit('UPDATE_FIELD_LOGIN', {
    property,
    payload,
  });
};

const updateFieldRecover = ({ commit }, { property, payload }) => {
  commit('UPDATE_FIELD_RECOVER', {
    property,
    payload,
  });
};

const updateFieldCreate = ({ commit }, { property, payload }) => {
  commit('UPDATE_FIELD_CREATE', {
    property,
    payload,
  });
};

const updateFieldNew = ({ commit }, { property, payload }) => {
  commit('UPDATE_FIELD_NEW', {
    property,
    payload,
  });
};

const updateNewPasswordFieldErrors = ({ commit }, { value }) => {
  commit('GENERIC_MUTATION', {
    property: 'newPasswordFieldErrors',
    payload: value,
  });
};

const updateIsLoading = ({ commit }, { value }) => {
  commit('GENERIC_MUTATION', {
    property: 'isLoading',
    payload: value,
  });
};

const updateLoginStep = ({ commit }, { value }) => {
  commit('GENERIC_MUTATION', {
    property: 'loginStep',
    payload: value,
  });
};

const inviteAccepted = async ({ commit }, { clientId, userId }) => {
  commit('GENERIC_MUTATION', {
    property: 'isLoading',
    payload: true,
  });

  await inviteAcceptedService({
    payload: { clientId, userId },
    onSuccess: () => {
      Vue.$toast.success('Convite aceito com sucesso');
    },
    onCustomError: ({ response }) => {
      const message =
        response?.data?.message ?? 'Ocorreu um erro de requisição ao aceitar o convite.';

      Vue.$toast.error(message);
    },
    onFinally: () => {
      commit('GENERIC_MUTATION', {
        property: 'isLoading',
        payload: false,
      });
    },
  });
};

export default {
  signIn,
  signInAd,
  callbackSigInAd,
  createOrUpdatePassword,
  resetPassword,
  updateFieldLogin,
  updateFieldRecover,
  updateFieldCreate,
  updateFieldNew,
  updateNewPasswordFieldErrors,
  updateIsLoading,
  updateLoginStep,
  inviteAccepted,
};
