import * as components from '@zitrus/z-components';

const componentsList = components;

const ZComponents = {
  install(Vue) {
    Object.keys(componentsList).forEach((name) => {
      Vue.component(name, componentsList[name]);
    });
  },
};

export default ZComponents;
