<template>
  <div class="z-title d-flex flex-column align-start">
    <h1>
      {{ title }}
    </h1>
    <h2 v-if="subtitle" class="font-weight-regular">
      {{ subtitle }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'ZTitle',
  props: {
    title: {
      type: String,
      default: 'Título',
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.z-title {
  h1 {
    font-size: 28px !important;
    font-weight: 700 !important;
  }

  h2 {
    font-size: 28px !important;
    font-weight: 400 !important;
  }
}
</style>
