import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import login from '@/views/login/store';

Vue.use(Vuex);

export default new Store({
  namespaced: true,
  modules: { login },
});
