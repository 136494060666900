import { createCommandService } from '@/services';
import { loginURI, zLicenceApi } from '@/api';

const signInService = ({ payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'post',
    uri: `${loginURI}/login/authenticate`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const signInAdService = async ({
  userLogin,
  authProviderType,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'get',
    uri: `${loginURI}/login?userLogin=${userLogin}&authProviderType=${authProviderType}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const callbackSigInAdService = async ({
  clientCode,
  code,
  state,
  onSuccess,
  onCustomError,
  onFinally,
}) =>
  createCommandService({
    method: 'get',
    uri: `${loginURI}/login/callback/${clientCode}?code=${code}&state=${state}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const createOrUpdatePasswordService = ({ token, payload, onSuccess, onCustomError, onFinally }) =>
  createCommandService({
    payload,
    method: 'put',
    uri: `${loginURI}/user/password/${token}`,
    onSuccess,
    onCustomError,
    onFinally,
  });

const resetPasswordService = async ({ email, onSuccess, onCustomError, onFinally }) => {
  createCommandService({
    method: 'put',
    uri: `${loginURI}/user/reset/password?email=${email}`,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

const inviteAcceptedService = ({
  payload: { clientId, userId },
  onSuccess,
  onCustomError,
  onFinally,
}) => {
  createCommandService({
    method: 'put',
    uri: `${zLicenceApi}/cliente-usuario/aceitar_acesso/${userId}/${clientId}`,
    onSuccess,
    onCustomError,
    onFinally,
  });
};

export {
  signInService,
  callbackSigInAdService,
  createOrUpdatePasswordService,
  resetPasswordService,
  signInAdService,
  inviteAcceptedService,
};
