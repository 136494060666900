<template>
  <div>
    <div v-if="this.$route.meta.layout !== 'convite'" class="container-login">
      <section class="container-login__left">
        <z-logo contain height="140px" width="311px" />
      </section>
      <section class="container-login__right">
        <div class="content-login">
          <div class="content-login__form">
            <v-overlay v-if="isLoading" absolute color="var(--neutral-gray-low)" opacity="0.6">
              <loader
                name="spinning"
                size="12"
                speed="1.5"
                opacity="60"
                disableScrolling="true"
                object="var(--secondary-lemon-medium)"
                objectbg="var(--primary-blue-base)"
                bg="white"
              />
            </v-overlay>
            <router-view />
          </div>
        </div>
      </section>
    </div>
    <router-view v-else> layout do convite </router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ZLogo } from '@/components';
import { getUserState, goToRoute } from '@zitrus/utils-service';

export default {
  name: 'ApplicationHome',
  components: {
    ZLogo,
  },
  computed: {
    ...mapState('login', ['isLoading']),
  },
  async created() {
    const user = getUserState();

    if (user) {
      goToRoute(`/workspace/selecionar-empresa?hideHeader=true&hideMenu=true`);
    }
  },
};
</script>

<style lang="scss" scoped>
.container-login {
  height: 100vh;

  background: linear-gradient(90deg, #21d7ac 80%, #1f747a);
  background-size: 110%;

  -webkit-animation: AnimationName 4s ease infinite;
  -moz-animation: AnimationName 4s ease infinite;
  animation: AnimationName 4s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 51%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  @-moz-keyframes AnimationName {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 51%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  @keyframes AnimationName {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 51%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  display: grid;
  grid-template: 'left right';
  grid-template-columns: 1fr 1fr;

  &__left {
    grid-area: left;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__right {
    grid-area: right;

    display: flex;
    justify-content: center;

    .content-login {
      position: relative;
      height: calc(100vh - 146px);
      padding: 0 166px 115px 166px;

      border-radius: 0 0 12px 12px;
      background-color: var(--neutral-gray-low);

      &__form {
        width: 388px;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        :deep(#overlay-spinner) {
          position: relative;
          z-index: 3;
        }

        :deep(.loader-spinner) {
          position: relative;
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .container-login {
    &__right {
      .content-login {
        margin: 0 50px;
        padding: 0 80px 115px 80px;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .container-login {
    height: 100%;
    grid-template-columns: 1fr;
    grid-template:
      'right'
      'left';
    gap: 40px;

    &__right {
      .content-login {
        margin: 0;
      }
    }

    &__left {
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-height: 768px) {
  .container-login {
    &__right {
      .content-login {
        height: calc(100vh - 60px);
        padding-bottom: 80px;
      }
    }
  }
}
</style>
