import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import '@/style/themeUpdate.scss';
import { UserPlugin } from '@zitrus/utils-service';
import { ZLibs } from '@zitrus/z-components';
import loader from 'vue-ui-preloader';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './services/instanceAxios';
import ZComponents from './index';

Vue.use(router);
Vue.use(UserPlugin);
Vue.use(ZComponents);
Vue.use(ZLibs);
Vue.use(loader);

Vue.config.productionTip = false;

Vue.prototype.$http = axios;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    router,
    vuetify,
    store,
  },
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
